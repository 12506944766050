import React from 'react';
import { Shake } from 'reshake';

import {ButtonTest} from './Button';

class Snowglobe extends React.Component {
  constructor(props) {
    super(props);
    this.scenes = ["willy", "kids", "village", "santa"];
    this.state = {isToggleOn: false, willyOn:true, sceneClass:this.scenes[0]};
 
    this.myvar = null;
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    //this.myFunction = this.myFunction.bind(this);
    this.alertFunc = this.alertFunc.bind(this);
  }


  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
      //willyOn: !state.willyOn 
    }));
    
    this.myVar = setTimeout(this.alertFunc, 1000);
    
  }
  

alertFunc(_timer) {
  this.setState(state => ({
    isToggleOn: !state.isToggleOn,
    sceneClass: this.switchScenes()
  }));
}

switchScenes(){
  var currentClass = this.state.sceneClass;
  var newClass = this.state.sceneClass;;
  do{
    newClass = this.scenes[Math.floor(Math.random() * this.scenes.length)];
  }while(currentClass === newClass )
  return newClass;
}

  render() {
    return (
      
        <div>

{/* <ButtonTest onClick={this.handleClick} text={this.state.sceneClass}></ButtonTest> */}
<div className="ground"></div> 
<Shake h={75} v={75} r={5} active={this.state.isToggleOn} fixed={true}
onClick={() => this.handleClick()}>
<div className="snowglobe">
<div className="sg-bg"></div>
<div className="grnd-snow">
<div className="snow-surface"></div>
</div>
<div className={'tree ' + (this.state.sceneClass !== "willy" && this.state.sceneClass !== "santa" ? "hidden":null)}>
<div className="tree-shadow"></div>
<div className="branch-bot">
<div className="branch-bot-snow"></div>
<div className="branch-shadow"> </div>
</div>
<div className="branch-mid">
<div className="branch-mid-snow"></div>
<div className="branch-shadow"> </div>
</div>
<div className="branch-top">
<div className="branch-top-snow"></div>
</div>
<div className="star"></div>
<div className="baubles"></div>
</div>
<div className={'sm-shadow ' + (this.state.sceneClass !== "willy" ? "hidden":null)}></div>
{/* <div className="snowman">
<div className="snowman-bot"></div>
<div className="snowman-top"></div>
<div className="coal"></div>
<div className="carrot"></div>
</div> */}
<div className={this.state.sceneClass}></div>
<div className="falling-snow-wrap">
<div className="falling-snow">
<div className="flakes1">
<p>*</p>
</div>
<div className={"shake1 " + (!this.state.isToggleOn ? "hidden":"")}>
<p>*</p>
</div>
<div className="flakes2">
<p>*</p>
</div>
<div className={"shake2 "  + (!this.state.isToggleOn ? "hidden":"")}>
<p>*</p>
</div>
</div>
</div>
<div className="sg-fg"></div>
<div className="stand-curved-wrap">
<div className="stand-curved"></div>
</div>
</div></Shake>
</div>
    );
  }
}

export{Snowglobe};