import React from 'react';
import './css/globe.css';
import { Snowglobe } from './classes/Snowglobe';




function Globe() {
  
    return (
       
     
      <Snowglobe>
        </Snowglobe>
     
    );
    

  }
  
  export default Globe;
  